@media only screen and (min-width: 1441px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1210px;
        padding: 0 15px;
    }






}



@media screen and (max-width:1240px) {


    
    .gallery_modal .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1rem 50px;
    }

    .gallery_modal .arrow_icon .slick-slider .slick-arrow.slick-prev {
        left: -35px;
    }

    .gallery_modal .arrow_icon .slick-slider .slick-arrow.slick-next {
        right: -35px
    }

    .gallery_modal .modal-header {
        top: 7px;
        right: 15px;
    }
}


@media screen and (min-width: 1200px) and (max-width: 1440px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1140px;
        padding: 0 15px;
        }


    .topbanner_slider .slick-dots li.slick-active button:before {
        background: #fff;
        height: 20px !important;
        opacity: 1;
    }
 

    .verticletype {
        transform: translate(-70px, -50%) rotate(180deg);
    }

    .section_title.verticletype {
        flex: 0 0 100%;
        max-width: 100%;
        transform: rotate(0);
        writing-mode: horizontal-tb;
        position: relative;
        left: 0;
        text-align: left;
        padding: 0 0 15px 0px;
    }
    
    .work_section .section_title.verticletype, .latest_exh .section_title.verticletype {
        padding-left: 15px;
    }





}


@media screen and (min-width: 992px) and (max-width: 1199px) {

    .newsevent .verticletype {
        left: -5px;
    }


    .date, .locationfix{font-size:12px;}
    .verticletype h2{font-size:46px;}

 

    .verticletype {
        transform: translate(-60px, -50%) rotate(180deg);
    }

    header .navbar-nav a {
        padding: 10px 8px !important;
        font-size: 15px;
    }

    .work-page_outer .list_info {
        padding: 0px 0 0 24px;
    }

    .work-page_outer .list_info p {
        font-size: 14px;
    }

    .other-shows-list .horizantel_grid .list_info {
        padding: 20px 0 0 36px;
    }

    .media_article {
        padding: 50px 0 20px 25px;
    }

    .videos_page .horizantel_grid .list_info {
        padding: 0px 0 0 24px;
    }

    .list_info h3 {
        line-height: 1.1;
        }

        .event_list_outer { 
            position: relative;
            }
            .verticletype{left:-20px;} 
            
            .verticletype{left:15px;}
            
            .horizantel_grid .list_info {
            padding: 24px 0 0 36px;
            }


            .section_title.verticletype {
                flex: 0 0 100%;
                max-width: 100%;
                transform: rotate(0);
                writing-mode: horizontal-tb;
                position: relative;
                left: 0;
                text-align: left;
                padding: 0 0 15px 0px;
            }
            
            .work_section .section_title.verticletype, .latest_exh .section_title.verticletype {
                padding-left: 15px;
            }

}





@media screen and (min-width: 768px) and (max-width: 991px) {
    .event_listing .list_info h3 a{font-size:16px;}

    .date, .locationfix{font-size:12px;}

    .verticletype{left:0;} 

    .list_info h3 {
        line-height: 0.7;
    }

    .list_info h3 {
        line-height: 1;
    }

  

    .verticletype {
        transform: translate(-50px, -50%) rotate(180deg);
    }

    .events_listing-outer .col-md-4 {
        padding: 0 15px;
    }

    .events_listing-outer {
        margin: 0 -15px;
    }

    .navbar-toggler {
        display: none;
    }

    .collapse:not(.show) {
        display: block;
    }

    header .navbar-nav {
        display: flex !important;
        flex-direction: row;
    }

    .navbar-light .navbar-nav .nav-link {
        font-size: 13px;
        padding: 10px 7px !important;
        margin: 0 5px;
    }

    .navbar-brand {
        font-size: 30px;
        padding: 10px 0;
    }

    .work_section {
        padding-top: 50px;
        padding-bottom: 36px;
    }

    .horizantel_grid .list_info {
        padding: 20px 32px;
    }

    .newsevent .btn.outline_btn {
        margin: 36px 0 0 0;
    }

    .social a {
        max-width: 41px;
    }

    footer .col-md-4:last-child {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 15px 0 0 0;
    }

    footer .col-md-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .list_info h3 a {
        font-size: 20px;
    }

    .list_info p {
        font-size: 14px;
        line-height: 20px;
    }

    .list_info {
        padding: 15px;
    }

    .list_info h3 a br {
        display: none;
    }

    .work_cols_outer .thumnail {
        padding: 0 15px;
    }

    .work_cols_outer .thumnail {
        margin-bottom: 32px;
    }

    .work_cols_outer {
        margin: 0 -15px;
    }

    .btn.outline_btn {
        margin: 24px 0 0 0;
    }

    .sm_verticle_list {
        padding: 0 15px;
    }

    .verticletype h2 {
        font-size: 40px;
    }

    .horizantel_grid .row {
        margin: 0;
    }

    .latest_exh_list_outer {
        margin: 0 -15px;
    }

    .newsevent .verticletype {
        transform: translate(-61px, -50%) rotate(180deg);
    }

    .btn.outline_btn {
        font-size: 16px;
        padding: 8px 15px;
    }

    .left_sidebar {
        flex: 0 0 250px;
        max-width: 250px;
    }

    .left_sidebar li a {
        font-size: 15px;
    }

    .pagemain_right {
        flex: 0 0 100%;
        max-width: calc(100% - 250px);
    }

    .Biography_detail {
        padding: 20px;
        flex: 0 100%;
        max-width: calc(100% - 150px);
    }

    .biography figure {
        flex: 0 0 150px;
    }

    .Biography_detail strong {
        font-size: 18px;
    }

    .Biography_detail small {
        font-size: 15px;
    }

    .right_main_contant p {
        padding: 10px 0;
    }

    .about_page {
        padding: 50px 0;
    }

    .page_title {
        padding: 24px 0;
    }

    .about_page {
        padding: 33px 0;
    }

    .work-page_outer .horizantel_grid .list_info {
        padding: 0px 0 0 20px;
        flex: 0 0 60%;
        max-width: 60%;
    }

    .work-page_outer .horizantel_grid figure {
        flex: 0 0 40%;
        max-width: 40%;
    }

    .list_info h3 {
        line-height: 1;
    }

    .work-page_outer .list_info {
        padding: 10px 0 0 24px;
    }

    .soloshows .horizantel_grid .list_info {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0px 0 0 24px;
    }

    .soloshows .horizantel_grid figure {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .soloshows .horizantel_grid .list_info h3 a {
        font-size: 20px;
    }

    .solor_venue {
        flex-wrap: wrap;
        padding-bottom: 0;
    }

    .media_article {
        padding: 40px 0 20px 25px;
    }

    .video_icon {
        width: 30px;
        margin: auto;
    }

    .event_left {
        padding-right: 15px;
        flex: 0 0 100%;
        max-width: calc(100% - 250px);
    }

    .event_detail_right {
        flex: 0 0 250px;
        max-width: 250px;
    }

    .event_detail_header {
        padding: 0px 15px 18px 15px;
        margin-bottom: 10px;
    }

    .event_detail_caption .date,
    .event_detail_caption .locationfix {
        font-size: 12px;
    }

    .event_detail_header .readmore {
        font-size: 13px;
    }

    .event_left p {
        padding: 0 15px;
        font-size: 13px;
    }


    .newsevent .horizantel_grid .list_info {
        padding: 0px 0 10px 20px;
    }
    .newsevent .horizantel_grid .list_info .readmore {
        margin: 0;
    }

    .section_title.verticletype {
        flex: 0 0 100%;
        max-width: 100%;
        transform: rotate(0);
        writing-mode: horizontal-tb;
        position: relative;
        left: 0;
        text-align: left;
        padding: 0 0 15px 0px;
    }
    
    .work_section .section_title.verticletype, .latest_exh .section_title.verticletype {
        padding-left: 15px;
    }

}


@media only screen and (max-width: 991px) {

    .work_detail .slick-slider .slick-slide > div {
        transform: scale(1);
    }

    .work_detail .slick-slider .slick-slide > div:after {
        height: calc(100% - 7px);
    }

    .work_detail .slick-slide > div {
        margin: 0 15px;
    }

    .work_detail_wrap {
        padding: 30px 0 0;
    }

    .detail_next_prev {
        padding: 30px 0;
        margin-top: 30px;
    }
    .media_article {
        column-count:8;       
    }

    header .navbar-nav a {
        color: #979899!important;
        font-size: 14px;
        padding: 16px 8px!important;
        display: block;
        position: relative;
        margin: 0 0px;
        letter-spacing: 1.3px;
        text-decoration: none;
    }

    .section_title.verticletype {
        flex: 0 0 100%;
        max-width: 100%;
        transform: rotate(0);
        writing-mode: horizontal-tb;
        position: relative;
        left: 0;
        text-align: left;
        padding: 0 0 15px 0px;
    }
    
    .work_section .section_title.verticletype, .latest_exh .section_title.verticletype {
        padding-left: 15px;
    }




    
}



@media only screen and (max-width: 767px) {

    .arrow_icon.gray_nav .slick-slider .slick-arrow.slick-next {
        right: 0;
        background-color: #fff;
        background-size: 16px;
    }
    .arrow_icon.gray_nav .slick-slider .slick-arrow.slick-prev {
        left: 0;
        background-color: #fff;
        background-size: 16px;
    }
    header{z-index:9;}

    .list_info h3 {
        line-height: 1;
    }

    .page_title h1 {
        font-size: 28px;
    }

    .page_title {
        padding: 30px 0;
    }

    .arrow_icon .slick-slider .slick-arrow.slick-next {
        right: 15px;
    }

    .arrow_icon .slick-slider .slick-arrow.slick-prev {
        left: 15px;
    }


    header .navbar {
        background: #f9f9f9 !important;
    }

    header .navbar-nav a {
        margin: auto;
        padding: 10px 0 !important;
        font-size: 14px;
        border-bottom: 1px solid #efefef;
    }



    header .navbar-nav a:last-child {
        border-bottom: none;
    }

    header .container {
        width: 100%;
        max-width: 100%;
    }


    .video_player {
        width: 40px;
    }

    .navbar-brand {
        font-size: 24px;
        text-align: left;
    }

    header .container {
        width: 100%;
        max-width: 100%;
    }

    .navbar-light .navbar-toggler {
        position: absolute;
        right: 15px;
        top: 10px;
        background: #f6f8fd;
        border: none;
    }

    .navbar-light .navbar-toggler:focus {
        outline: none;
        box-shadow: none;
    }

    header .navbar {
        position: initial;
        border: none;
        padding: 0;
    }

    header {
        position: relative;
    }

    .work_section,
    .latest_exh {
        padding-top: 36px;
        padding-bottom: 36px;
    }

    .latest_exh_list_outer {
        padding: 0;
    }

    .horizantel_grid .list_info {
        padding: 24px 15px;
    }

    .list_info {
        padding: 24px 15px;
    }

    .sm_verticle_list,
    .lg_verticle_list {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px;
        margin-bottom: 10px;
    }

    .verticletype {
        writing-mode: lr;
        transform: translate(0px, -0) rotate(0);
        position: initial;
        top: auto;
        flex: 0 0 100%;
        max-width: 100%;
        text-align: left;
    }

    .latest_exh_list_outer {
        flex-wrap: wrap;
    }

    .verticletype h2 {
        font-size: 30px;
        margin: 0 0 15px 0;
    }

    .work_cols_outer .thumnail {
        padding: 0;
    }

    .large_grid_pic,
    .small_grid_pic {
        padding: 0 15px;
    }

    .work_cols_outer {
        margin: 0 -15px;
    }

    .btn.outline_btn {
        margin-top: 36px;
    }

    .sm_verticle_list figure img {
        width: 100%;
    }

    .work_section .verticletype,
    .latest_exh .verticletype {
        padding: 0 15px;
    }

    .event_list_outer {
        padding: 10px 0;
    }

    .newsevent .btn.outline_btn {
        margin: 24px 0 0 0;
    }

    footer .col-md-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    footer .col-md-4:last-child {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 15px 0 0 0;
    }

    .btn.outline_btn {
        font-size: 14px;
        padding: 8px 20px;
    }

    .list_info h3 a {
        font-size: 20px;
    }

    .list_info p {
        font-size: 14px;
        line-height: 19px;
    }



    .page_title {
        padding: 20px 0;
    }

    .container,
    .container-sm {
        max-width: 100%;
        padding: 0 15px;
    }

    .page_title h2 {
        font-size: 32px;
        margin: 0;
        padding: 15px 0;
    }

    .events_listing-outer .col-md-4 {
        padding: 0 15px;
    }

    .events_listing-outer {
        margin: 0 -15px;
    }

    .left_sidebar {
        position: fixed;
        background: #fbfbfb;
        top: 0;
        bottom: 0;
        left: 0;
        padding: 24px;
        width: 250px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
        transition: all .3s;
        -webkit-transition: all .3s;
        transform: translateX(-260px);
        z-index:9;
    }

    .about_page {
        padding: 36px 0;
    }

    .pagemain_right {
        padding: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .biography figure {
        flex: 0 0 120px;
    }

    .Biography_detail {
        padding: 10px;
    }

    .Biography_detail strong {
        font-size: 18px;
    }

    .Biography_detail small {
        font-size: 15px;
    }

    .right_main_contant p {
        padding: 10px 0;
        line-height: 1.5;
        margin: 0;
    }

    .detail_content {
        padding-top: 10px;
    }

    .work_detail .slick-slide > div {
        margin: 0 10px;
    }

    .cv-0detail-list {
        padding-top: 0;
    }

    .cv-0detail-list h4 {
        font-size: 20px;
    }


    .soloshows .horizantel_grid .list_info {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0px 0 0 24px;
    }

    .soloshows .horizantel_grid figure {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .soloshows .horizantel_grid .list_info h3 a {
        font-size: 20px;
    }

    .solor_venue {
        flex-wrap: wrap;
        padding-bottom: 0;
    }

    .media_page h3 {
        font-weight: bold;
        font-size: 20px;
    }

    .media_article {
        padding: 32px 0 10px 25px;
    }

    .videos_page .horizantel_grid .list_info {
        flex: 0 0 53%;
        max-width: 53%;
        padding: 10px 0 0 24px;
    }

    header .navbar-nav a:before {
        background-color: #efefef00;
    }

    .event_left {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
    }

    .event_detail_right {
        border: 1px solid #E8E8E8;
        flex: 0 0 100%;
        max-width: 100%;
        margin: 10px 0;
        padding: 15px 20px;
    }

    .event_left p {
        padding: 0;
        font-size: 14px;
    }

    .event_detail_header {
        margin-bottom: 10px;
        padding: 0 0 15px 0;
    }

    .event_detail_info_part {
        padding-top: 15px;
    }

    .event_detail_right ul {
        display: flex;
        flex-wrap: wrap;
    }

    .event_detail_right ul a {
        padding: 5px 12px;
        border: 1px solid #e8e8e8;
        border-radius: 50px;
        font-size: 12px;
    }

    .event_detail_right ul li a {
        margin: 0 7px 5px 0;
    }

    .event_detail_right .social {
        padding: 10px 0 0 0;
    }

    .event_detail_right .social h6 {
        font-size: 15px;
    }

    .sidenav_toggle {
        transform: translateX(-3px);
        top: 140px;
    }



    .side_nav .left_sidebar {
        transform: translate(0);
        z-index: 2;
    }
    
    .side_nav:after {
        background: rgb(0 0 0 / 50%);
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    
    .side_nav .sidenav_toggle {
        top: 10px;
        left: 250px;
        z-index: 2;
    }
    
    .left_sidebar li,.subnav-sidebar li a{font-size:14px;}
    
    
    .horizantel_grid figure img:not(.video_icon){width:100%;}
    
    .horizantel_grid:nth-child(odd) .row .list_info {
        padding-left: 0;
        padding: 15px 0;
    }
    
    .newsevent .horizantel_grid .list_info .readmore {
        margin: 0;
    }
    
    .newsevent .horizantel_grid .list_info {
        padding: 15px 0;
    }
    .work-page_outer .horizantel_grid .list_info {
        padding: 0 0 0 20px;
    }
    
    .work-page_outer .horizantel_grid {
        padding: 20px 0;
    }

    .media_article {
        column-count:4;       
    }
    .media_article a img{width:100%;}

    .right-nav { display: block; }
    
    .left_sidebar.openside .right-nav {
        left: 215px;
        position: absolute;
        top: 0;
        transform: rotate(-180deg);
        /* background: #fbfbfb; */
        padding: 0 5px;
        font-size: 17px;
        margin: 5px;
    }
    .right-nav {
        background: #fff;
        width: 40px;
        height: 40px;
        box-shadow: 0 0 5px rgba(0,0,0,.2);
        position: fixed;
        top: 123px;
        left: 0;
        z-index:9;
    }

    .topbanner_slider .slick-dots{display:none !important;}
    .video_list_info{font-size:16px;}


    .section_title.verticletype {
        flex: 0 0 100%;
        max-width: 100%;
        transform: rotate(0);
        writing-mode: horizontal-tb;
        position: relative;
        left: 0;
        text-align: left;
        padding: 0 0 15px 0px;
    }
    
    .work_section .section_title.verticletype, .latest_exh .section_title.verticletype {
        padding-left: 10px;
    }

}




@media only screen and (max-width: 575px) {

    .slick-prev:after, .slick-next:after {
        width: 34px!important;
        height: 34px;
        background-size: 20px;
    }
    .right-nav {
        background: #fff;
        width: 30px;
        height: 30px;
        box-shadow: 0 0 5px rgb(0 0 0 / 20%);
        position: fixed;
        top: 100px;
        left: 0;
        z-index: 9;
    }
    .left_sidebar.openside .right-nav i {
        top: 0;
    }
    .media_article {
        column-count:2;       
    }
    .right-nav i{font-size:18px; position:relative; top:-4px;}


    .event-page .event-tabs{font-size:13px; padding:6px 10px;}
    .events_listing-outer{padding:0 10px;}

    .latest_exh .btn.outline_btn,.newsevent .btn.outline_btn{margin:5px; width:90%; margin-left:auto; margin-right:auto;}


    .sidenav_toggle{top:100px;}
    .gallery_modal button.close {width:18px; height: 18px;}
    .thumbnail .slick-dots {margin-top:5px; }
    .arrow_icon .slick-slider .slick-arrow.slick-next {
        right: 5px;
        background-size: 70%;
    }

    .arrow_icon .slick-slider .slick-arrow.slick-prev {
        left: 5px;
        background-size: 70%;
    }

    footer {
        padding: 20px 0;
    }

    .detail_next_prev {
        padding: 15px 0;
        margin-top: 20px;
    }

    .detail_content h2 {
        font-size: 21px;
    }

    .work_detail .slick-slide > div {
        margin: 0 5px;
    }

    .page_title h1 {
        font-size: 20px;
    }

    .btn.outline_btn {
        padding: 8px 10px;
    }

    .work_cols_outer .thumnail .btn {
        font-size: 10px;
        padding: 8px 3px;
        width: 76px;
    }

    .large_grid_pic {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .small_grid_pic {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 11px 0;
    }

    .navbar-brand {
        font-size: 20px;
        text-align: left;
    }

    .work_section,
    .latest_exh {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .verticletype h2 {
        font-size: 24px;
        margin: 0 0 15px 0;
    }

    .large_grid_pic,
    .small_grid_pic {
        padding: 0 5px;
    }

    .small_grid_pic .thumnail {
        margin-bottom: 12px;
    }

    .work_cols_outer {
        margin: 0 -5px;
    }

  
    .latest_exh_list_outer {
        margin: 0 -10px;
    }

    .sm_verticle_list,
    .lg_verticle_list {
        padding: 0 10px;
    }

    .list_info {
        padding: 24px 0px;
    }

    .latest_exh_list_outer {
        margin: 0 -10px;
    }

    .list_info h3 {
        line-height: 1.1;
    }

    .newsevent {
        padding: 15px 0;
    }

    .horizantel_grid .list_info {
        padding: 0 0 0 15px;
    }

    .list_info {
        padding: 15px 0;
    }

    

    footer .col-md-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .latest_exh_list_outer {
        margin: 0 -10px;
    }

    .list_info h3 {
        line-height: 1.1;
    }

    .newsevent {
        padding: 15px 0;
    }



    .list_info {
        padding: 15px 0;
    }
 


    .social {
        margin: 0 0 15px 0;
    }

    .coppyright {
        text-align: left;
    }


    .social a img {
        max-width: 20px;
    }

    .event_listing .list_info {
        padding: 10px;
    }

    .event_listing {
        margin-bottom: 20px;
    }

    .event_listing .list_info h3 a {
        font-size: 16px;
        letter-spacing: 1px;
    }

    .page_title {
        padding: 10px 0;
    }

    .page_title h2 {
        font-size: 24px;
        margin: 0;
        padding: 10px 0;
    }



    .event_listing .list_info .readmore {
        margin: 10px 0 0 0;
        display: block;
    }

    .biography figure {
        flex: 0 0 80px;
    }

    .Biography_detail {
        padding: 10px;
        flex: 0 0 100%;
        max-width: calc(100% - 80px);
    }

    .biography {
        margin-bottom: 10px;
    }

    .about_page {
        padding: 24px 0;
    }

    .page_title h1 {
        font-size: 24px;
    }

    .biography {
        margin-bottom: 0;
    }

    .cv-0detail-list {
        padding-top: 0;
    }

    .cv-0detail-list h4 {
        font-size: 18px;
    }

    .right_main_contant p {
        font-size: 14px;
        padding: 6px 0;
    }

    .work-page_outer .horizantel_grid {
        padding: 10px 0;
    }

    .work-page_outer .horizantel_grid figure,
    .soloshows .horizantel_grid figure {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .horizantel_grid figure img:not(.video_icon) {
        width: 100%;
    }

    .work-page_outer .horizantel_grid .list_info,
    .soloshows .horizantel_grid .list_info {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 15px 0;
    }

    .work-page_outer .list_info h3 a {
        font-size: 18px;
    }

    .work-page_outer .list_info p {
        font-size: 14px;
    }

    .work-page_outer .horizantel_grid:last-child .list_info {
        padding-bottom: 0;
    }

    .soloshows .horizantel_grid {
        padding: 10px 0;
    }

    .other-shows-list .horizantel_grid {
        margin-bottom: 20px;
    }

    .media_article {
        padding: 20px 0 0px 25px;
    }

    .media_article ul {
        column-count: 5;
        grid-column-gap: 8px;
        column-gap: 8px;
    }

    .publications_outer .media_link {
        padding: 10px 0;
    }

    .event_detail_right ul li a {
        font-size: 11px;
    }

    .event_detail_right {
        padding: 10px 15px;
    }

    .event_detail_header {
        flex-wrap: wrap;
    }

    .event_detail_header {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .event_detail_header .readmore {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 15px 0 0;
    }

    .thumbnail .slick-dots li {
        width: 70px;
    }
    .side_btn {
        margin-left: 0px !important;
    }
    .verticletype h2{margin-bottom:0!important;}


}

@media only screen and (max-width: 460px) {
    .gallery_modal .modal-body {
        padding: 1rem 30px;
    }

    .thumbnail .slick-dots li {
        width: 50px;
    }

    .work_detail_wrap {
        padding: 20px 0 0;
    }

    .page_title h1 {
        font-size: 18px;
        letter-spacing: 0;
    }

    .page_title .btn.outline_btn {
        letter-spacing: 0px;
    }

    .cv-0detail-list h4 {
        font-size: 16px;
    }

    .soloshows .horizantel_grid .list_info h3 a {
        font-size: 16px;
    }

    .date,
    .locationfix {
        font-size: 13px;
    }

    .list_info p {
        font-size: 14px;
    }

    .media_page h3,
    .videos_page h2 {
        font-size: 16px;
    }

    .videos_page .horizantel_grid .list_info h3 a {
        font-size: 16px;
        font-weight: 500;
    }

    .media_link li a {
        font-size: 14px;
    }

    .media_article ul {
        column-count: 3;
        grid-column-gap: 6px;
        column-gap: 6px;
    }

    .media_article {
        padding: 10px 0 0px 25px;
    }

    .media_link li {
        padding-left: 20px;
    }

    .navbar-light .navbar-toggler-icon {
        width: 1em;
        height: 1em;
    }

    .video_icon {
        width: 36px;
    }
    .date_locate .locationfix, .link_cat h5 + h5 {
        margin-left: 14px;
        font-size: 13px;
    }
    .link_cat h5, .link_cat a {    font-size: 13px;}

    .media_article {
        column-count:1;       
    }
  

}

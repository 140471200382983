
/* font */ 
@font-face {
    font-family: 'SF_UI_Display';
    src: url('../fonts/SFUIDisplay-Black.woff2') format('woff2'),
        url('../fonts/SFUIDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF_UI_Display';
    src: url('../fonts/SFUIDisplay-Bold.woff2') format('woff2'),
        url('../fonts/SFUIDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF_UI_Display';
    src: url('../fonts/SFUIDisplay-Light.woff2') format('woff2'),
        url('../fonts/SFUIDisplay-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF_UI_Display';
    src: url('../fonts/SFUIDisplay-Regular.woff2') format('woff2'),
        url('../fonts/SFUIDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF_UI_Display';
    src: url('../fonts/SFUIDisplay-Medium.woff2') format('woff2'),
        url('../fonts/SFUIDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF_UI_Display';
    src: url('../fonts/SFUIDisplay-Semibold.woff2') format('woff2'),
        url('../fonts/SFUIDisplay-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}



@keyframes outside_border {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    }
    100% {
      box-shadow: 0 0 0 60px rgba(0, 0, 0, 0);
    }
  }


  @-webkit-keyframes outside_border {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    }
    100% {
      box-shadow: 0 0 0 60px rgba(0, 0, 0, 0);
    }
  }


/* 

@keyframes outside_border {
   0%{border: 0px solid rgba(255,255,255,0.30);}
   20%{border: 15px solid rgba(255,255,255,0.25);}
   40%{border: 30px solid rgba(255,255,255,0.20);}
   60%{border: 45px solid rgba(255,255,255,0.15)}
   80%{border: 60px solid rgba(255,255,255,0.10); }    
   100%{border: 75px solid rgba(255,255,255,0.05); }    
  }

 @-webkit-keyframes outside_border {
    0%{border: 0px solid rgba(255,255,255,0.30);}
    20%{border: 15px solid rgba(255,255,255,0.25);}
    40%{border: 30px solid rgba(255,255,255,0.20);}
    60%{border: 45px solid rgba(255,255,255,0.15)}
    80%{border: 60px solid rgba(255,255,255,0.10); }    
    100%{border: 75px solid rgba(255,255,255,0.05); }    
   } */
 
  
   /* sfu */
  

body{font-family: 'SF_UI_Display'; letter-spacing:1px; color:#303133; font-size:15px; overflow-x:hidden;}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{letter-spacing:1.2px;}

a:hover { color: #2f4a91; transition: all .3s; -webkit-transition: all .3s;}

img{max-width:100%;}

ol, ul {
    padding-left:0;
}

.verticletype {
    white-space: nowrap;
}

.thumnail img {
    width: 100%;
}

.verticle_grid figure img:not(.video_icon) {
    width: 100%;
}

.horizantel_grid figure img {
    max-width: 100%;
}

.banner_slider a {
    display: inline-block;
    width: 100%;
} 
.banner_slider a {
    display: inline-block;
    width: 100%;
} 

.banner_slider a video {
    width: 100%;
}




.navbar-brand {
    display: block;
    padding: 15px 0;
    text-align: center;
    margin: 0;
    font-size: 40px;
    font-weight: bold;
    color: #2B3F73;
    letter-spacing: 1.8px;
    line-height: 1.3;
}

.navbar-brand a {
    color: inherit;
    text-decoration: none;
}
header .navbar {
    background-color: transparent!important;
    border-top: 1px solid #E8E8E8;
    padding:0;
}

header .navbar-nav {
    justify-content: center;
    margin: auto;
}

header .navbar-nav a {
    color: #979899!important;
    font-size: 16px;
    padding: 16px 12px!important;
    display: block;
    position: relative;
    margin:0 15px;
    letter-spacing: 1.3px;
    text-decoration: none;
}

header .navbar-nav a:before {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    background: #000;
    top: -1px;
    left: 0;
    right: 0;
    margin: auto;
    transition: all .3s;
    -webkit-transition: all .3s;
}

header .navbar-nav a.active:before{width:100%;}

header .navbar-nav a:hover:before{width:100%;} 

header {
    border-top: 4px solid #2B3F73;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{color:#2B3F73!important;}
.topbanner_slider{position:relative;}
 
.topbanner_slider .slick-dots {
    position: absolute;
    top: 0;
    left: auto;
    z-index: 2;
    bottom: 0;
    right: 0;
    width: 30px;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 22;
    bottom:auto;
    display: none !important;
}

.topbanner_slider .slick-dots li button:before {
    width: 3px;
    height: 10px!important;
    font-size: 0;
    background: rgba(255 255 255 / 100%);
    border-radius: 50px;
    transition: all .3s;
    position: relative;
    content: "";
    display: block;
    opacity: 0.5;
    margin: auto;
    padding:2px;
} 

.topbanner_slider .slick-dots li button {
    width: 100%;
    height: auto;
    margin: 0;
    text-align: center;
    padding:2px 5px;
}
 

.topbanner_slider .slick-dots li {
    width: auto;
    margin: 0;
    display: block;
    height: auto;
    margin: 10px 0;
}
 
.topbanner_slider .slick-dots li.slick-active button:before {
    background: #fff;
    height: 46px!important;
    opacity: 1;
}
.video_player {
    position: absolute;
    top: 50%;
    z-index: 2;
    margin: auto;
    left: 50%;
    transform: translatey(-50%) translatex(-50%);
    border-radius:100px;     
    animation: outside_border 3s infinite;
    -webkit-animation: outside_border 3s infinite;
}

.video_player:after{
    content: "";   
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translatey(-50%) translatex(-50%);
    width: 50px;
    height: 50px;
    display: block;}

.banner_slider {
    position: relative;
}

.topbanner_slider .slick-prev:after,.topbanner_slider .slick-next:after {
    content: ""!important;
    width: 46px!important;
    height: 65px;
    background: #000;
    position: absolute;
    color: #fff!;
    z-index: 9999;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: center;
  
}
.topbanner_slider .slick-prev:after{
    background-image: url(../images/next.png);
    transform: rotate(180deg);

}
.topbanner_slider .slick-next:after{
    background-image: url(../images/next.png);
    right: 0;
   
}

.topbanner_slider .slick-prev {
    position: absolute;
    left: 0px;
    color: #fff;
    left: auto;
    top: 50%;
    z-index: 9;
    display: none !important;
    /* transform: rotate(90deg);     */
    /* background-color: #000; */

}

.topbanner_slider .slick-next {
    position: absolute;
    right: 0px;
    color: #fff;
    left: auto;
    top: 50%;
    z-index: 9;
    display: none !important;
    /* transform: rotate(
90deg); */
   
}
 
.verticletype h2 {
    margin: 0;
    font-size: 45px;
    color: #979899;
    font-weight: lighter;
    letter-spacing:5px;
}

.verticletype {
    position: absolute;
    top: 50%;
    transform: translate(-90px, -50%) rotate(180deg);
    writing-mode:vertical-lr;
    left:0;
}

section {
    position: relative;
}

.work_cols_outer {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -18px;
    position: relative;
}

.work_cols_outer .thumnail {
    padding: 0 18px;
}

.work_cols_outer figure{margin:0;}

.small_grid_pic .thumnail {
    margin-bottom: 39px;
}

.small_grid_pic .thumnail:last-child {
    margin-bottom: 0;
}
.work_section {
    padding: 80px 0 48px 0;
}

 
.work_section {
    text-align: center;
}

.btn.outline_btn span {
    margin-left: 10px;
}

.btn.outline_btn {
    border: 1px solid #2B3F73;
    border-radius: 0;
    font-size: 15px;
    padding: 10px 20px;
    margin: 48px 0 0 0;
    color: #2B3F73;
 
}
.btn.outline_btn:hover span {
    margin-left: 20px;
}
.work_section {
    text-align: center;
}

.btn.outline_btn span {
    margin-left: 10px;
    transition:all ease-in-out .3s;
    -webkit-transition:all ease-in-out .3s;
}

.work_cols_outer .thumnail {
    position: relative;
}

.work_cols_outer .thumnail .btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translatey(-50%);
    margin: 0;
    max-width: 152px;
    margin: auto;
    background: rgba(0, 0, 0, 0.46);
    color: #fff;
    border-color: #fff;
    transition: all ease-in-out .3s;
    -webkit-transition: all ease-in-out .3s;
    transform: scale(0);
    z-index: 2;
}

.work_cols_outer figure {
    position: relative;
}

.work_cols_outer figure:after {content: "";position: absolute;top: 0;bottom: 0;left: 0;right: 0;background: rgba(0, 0, 0, 0.31);z-index: 1;opacity: 0;transition: all .3s;-webkit-transition: all .3s;}

.work_cols_outer .thumnail:hover figure:after {
    opacity: 1;
}

.work_cols_outer .thumnail:hover .btn.outline_btn {
    transform: scale(1);
}

.large_grid_pic {
    flex: 0 0 66.5%;
    max-width: 66.5%;
}

.small_grid_pic {
    flex: 0 0 33%;
    max-width: 33%;
}
.latest_exh {
    padding: 70px 0 50px 0;
    background: #FCFCFC;
}

.latest_exh_list_outer {
    display: flex;
    flex-wrap: wrap;
}

.latest_exh {
    padding: 10px 0 48px 0;
    background: #FCFCFC;
    text-align: center;
}

.latest_exh_list_outer {
    display: flex;
    margin: 0 -20px;
}

.sm_verticle_list {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 20px;
}

.lg_verticle_list {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 20px;
}

.list_info {
    padding: 24px;
    text-align:left;
}

.list_info h3 {
    font-weight: normal;
    margin-top: -10px !important;
    margin: 0;
} 
.list_info h3 a {font-size: 25px;text-decoration: none;color: #303133; letter-spacing: 1.2px;}

.date, .locationfix {
    font-size: 14px;
    color: #979899;
    padding: 6px 0 0 0;
    display: block;
}
.list_info p {
    color: #979899;
    font-size: 16px;
    padding: 10px 0;
    margin: 0;
    line-height: 21px;
    text-align: justify;
}


.latest_exh_list_outer {
    position: relative;
    padding: 50px 0 20px 0;
}

.latest_exh .btn.outline_btn {
    margin-top: 0!important;
}
 

.readmore {
    font-size: 14px;
    color: #2B3F73;
    text-decoration: none;
    display: block;
    margin: 5px 0 0 0;
    transition: all .3s;
    -webkit-transition:all .3s ;
}
.readmore:hover img {
    margin-left: 20px;
}
.readmore  img {transition:all 0.3s; -webkit-transition:all 0.3s;
    margin: 0 0 0 10px;
}

figure {
    margin: 0;
}
.date img {
    margin-right: 6px;
}
.locationfix img{margin-right:8px; width: 12px ;}


.newsevent {
    padding:32px 0;
} 

.event_list_outer {
    padding: 20px 0;
    position:relative;
}

.verticletype {
    white-space: nowrap;
}

.horizantel_grid:nth-child(odd) .row {
    flex-direction: row-reverse;
}

.event_list_outer .horizantel_grid .col-md-6 {
    padding: 0;
}

.horizantel_grid .row {
    margin: 0;
}

.horizantel_grid .list_info {
    padding: 50px 0 0 50px;
}

.newsevent .horizantel_grid:nth-child(odd) .list_info {
    padding: 50px 50px 0 0;
}

.newsevent {
    text-align: center;
}

.newsevent .btn.outline_btn {
    margin: 58px 0 0 0;
}

.banner_slider a img {
    width: 100%;
}
 
.social a + a {
    padding: 0 10px;
}

.social {
    align-items: center;
    display: flex;   
}

footer {
    padding: 40px 0;
    background: #FCFCFC;
    border-top: 1px solid #E5E5E5;
}
footer .row{align-items:center;}

.coppyright {
    font-size: 13px;
    color: #979899;
    display: block;
    text-align: center;
} 

.newslatter h5 {
    font-size: 16px;
    color: #303133;
}

.newslatter form {
    border: 1px solid #ddd;
    position: relative;
}


.newslatter form input {
    border: none;
    padding: 10px 10px;
    font-size: 12px;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 124px;
}

.newslatter form .subscribe {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 13px;
    border-radius: 0;
    background: #2B3F73;
    border-color: #2B3F73;
    width: 114px;
}

.newslatter form .subscribe:hover{  background: #3857a7;}
 
.form-control:focus,.btn:focus {
    box-shadow: none;
    outline: none;
}


.gototop {display:none;
    position: fixed;
    right: 44px;
    bottom: 136px;
}



/**=========about-us=============**/

.page_title {
    background-color: #2B3F73;
    padding: 15px 0;
    color: #fff;
}

.page_title h1 {
    font-size: 25px;
    margin: 0;    
    font-weight:200; 
}

.about_page {
    padding: 70px 0;
} 

.biography {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.Biography_detail strong {
    display: block;
    font-weight: bold;
    font-size: 22px;
}

.Biography_detail small {
    color: #777777;
    font-size: 16px;
}

.Biography_detail {
    padding: 24px;
}
  

.right_main_contant p {
    padding: 15px 0 0 0;
    line-height: 21px;
    margin-bottom: 10px;
    text-align: justify;
}
  
.left_sidebar li a {
    text-decoration: none;
    color: #303133;
    transition: all .3s;
    -webkit-transition: all .3s;
}

.left_sidebar li {
    list-style: none;
    font-size: 18px;
    line-height: 1.8;
    margin:2px 0;
}

.subnav-sidebar {
    padding-left: 0;
     
}

.subnav-sidebar li a {
    font-size: 16px;
    color: #777777;
}
.left_sidebar li a {
    position: relative;
}
.left_sidebar li a:before {content: "";height: 1px;width: 0;background: #000;position: absolute;left: 0;top: -5px; transition: all .3s; -webkit-transition:all .3s;}
.left_sidebar li.active > a:before, .left_sidebar li > a:hover:before { width: 100%; }

.cv-0detail-list h4 {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
    letter-spacing:1.3px;
}

.cv-0detail-list p {
    margin: 0;
    padding: 10px 0;
    font-size: 15px;
}

.cv-0detail-list p small {
    display: block;
    font-size: 14px;
    color: #777777;
}

.cv-0detail-list {
    border-bottom: 1px solid #E8E8E8;
    padding: 20px 0px;
    margin-bottom: 20px;
}

.cv-0detail-list:first-child{padding-top:0;}

.cv-0detail-list ul:last-child {
    margin-bottom: 0;
}

.cv-0detail-list:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
}

.viewmore {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: #2B3F73;
    background: transparent;
    border: none;
    padding: 0;
    margin: 10px 0;
}

.subtitle {
    color: #777777;
    font-weight: bold;
    padding: 10px 0;
    font-size: 16px;
    display: block;
}

.cv-0detail-list ul {
    padding-left: 28px;
}

.cv-0detail-list ul li {
    padding: 3px 0;
}


.event_listing .list_info {
    padding: 10px 16px;
    min-height: 225px;
}

.event_listing .list_info h3 {    
    margin-bottom: 5px;
    font-weight: 500;
}

.event_listing .list_info h3 a {
    font-size: 18px;
    letter-spacing: 1px;
}

.events_listing-outer {
    margin: 0 -20px;
}

.events_listing-outer .col-md-4 {
    padding: 0 20px;
}
 

.event_listing {
    margin-bottom: 36px;
}
.event_listing .list_info .readmore {
    margin: 15px 0 0 0;
    display: block;
}

.page_main_outer {
    display: flex;
    flex-wrap: wrap;
}

.left_sidebar {
    flex: 0 0 336px;
    max-width: 336px;
}

.pagemain_right {
    flex: 0 0 100%;
    max-width: calc(100% - 336px);
    padding: 0 0 0 20px;
}

/**====work====**/

.work-page_outer .horizantel_grid:nth-child(odd) .row {
    flex-direction: initial;
}

.work-page_outer .list_info {
    padding:0px 0 0 24px ;
}

.work-page_outer .horizantel_grid > .row {
    margin-bottom: 60px;
}

.work-page_outer .list_info h3 a {
    font-size: 20px;
}

.work-page_outer .list_info p {
    padding-top: 4px;
    font-size: 15px;
}

.work-page_outer .horizantel_grid > .row:last-child {
    margin-bottom: 0;
}
.work-page_outer .horizantel_grid .col-md-7,.work-page_outer .horizantel_grid .col-md-5 {
    padding: 0;
}
 
.work-page_outer .horizantel_grid {
    display: flex;
    flex-wrap: wrap;
}

.work-page_outer .horizantel_grid figure {
    flex: 0 0 53%;
    max-width: 53%;
}

.work-page_outer .horizantel_grid .list_info {
    flex: 0 0 47%;
    max-width: 47%;
}
.work-page_outer .horizantel_grid {
    display: flex;
    flex-wrap: wrap;
    padding: 45px 0;
    border-bottom: 1px solid #E8E8E8;
}

.work-page_outer .horizantel_grid:last-child{ border-bottom:none;}

.work-page_outer .horizantel_grid figure {
    flex: 0 0 53%;
    max-width: 53%;
}

.work-page_outer .horizantel_grid .list_info {
    flex: 0 0 47%;
    max-width: 47%;
}

.work-page_outer .horizantel_grid:first-child {
    padding-top: 0;
}

.work-page_outer .horizantel_grid:last-child {
    padding-bottom: 0;
}



.soloshows .horizantel_grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 50px 0;
    margin: 0 0 45px 0;
    border-bottom: 1px solid #E8E8E8;
}

.soloshows .horizantel_grid:last-child{padding-bottom:0; margin-bottom:0; border-bottom:none;}

.soloshows .horizantel_grid figure {
    flex: 0 0 55%;
    max-width: 55%;
}

.soloshows .horizantel_grid .list_info {
    flex: 0 0 45%;
    max-width: 45%;
    padding:5px 0 0 36px;
}

.soloshows .horizantel_grid .list_info h3 {
    font-weight: bold;
    line-height:1;
}
.soloshows .horizantel_grid:first-child {
    padding-top: 0;
}

.soloshows .horizantel_grid:last-child {
    padding-bottom: 0;
}
.solor_venue {
    display: block;
    justify-content: space-between;
    padding: 6px 0 10px 0;
}

.solor_venue span {
    padding-right: 15px;
} 
.other-shows-list .horizantel_grid figure {
    flex: 0 0 50%;
}

.other-shows-list .horizantel_grid .list_info {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 5px 0 0 50px;
}

.other-shows-list .horizantel_grid {
    border-bottom: 1px solid #E8E8E8;
    padding: 0 0 50px 0;
    margin: 0 0 45px 0;
}

.other-shows-list .horizantel_grid:last-child{padding-bottom:0; margin-bottom:0; border-bottom:none;}
.other-shows-list .horizantel_grid:nth-child(even) {
    flex-direction: row-reverse;
} 
.other-shows-list .horizantel_grid:nth-child(even) .list_info {
    padding: 5px 50px 0 0;
} 
.soloshows .horizantel_grid .list_info h3 a {
    font-size: 22px;
} 
 

.media_page h3 {
    font-weight: bold;
    font-size: 22px;
}

.media_link li a {
    font-size: 15px;
    text-decoration: none;
    color: #303133;
    font-weight: 500;
}

.media_link li {
    padding: 10px 0;
    list-style: none;
    position: relative;
    padding-left: 25px;
    line-height: 1.7;
}
 

.media_link li span {
    color: #777777;
    display: block;
    font-size: 14px;
}

.media_link li:before {
    content: "";
    width: 1px;
    height: 1px;
    border-top: 8px solid #979899;
    border-right: 8px solid transparent;
    position: absolute;
    left: 0;
    top: 20px;
    transform: rotate(136deg);
    left: -4px;
}

.publications_outer {
    padding: 0;
}.publications_outer .media_link {
    padding: 20px 0;
}.media_article {
    padding: 70px 0 20px 25px;
}

.media_article > a {
    /* max-width: 95px; */
    list-style: none;
    vertical-align: top;
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    page-break-inside: avoid;
    break-inside: avoid;
}

.media_article > a img {
    border: 1px solid #eee;
}

.media_article {
    column-count: 12;
    grid-column-gap: 10px;
    column-gap: 10px;
}

.link_copy{
    cursor: pointer;
    width: 18px;
    margin-left: 8px;
}

.side_btn {
    margin-left: 20px !important;
}

.videos_page .horizantel_grid:first-child {
    padding-top: 20px !important;
}

.videos_page .horizantel_grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 50px 0;
    margin: 0 0 45px 0;
    border-bottom: 1px solid #E8E8E8;
    align-items:flex-start;
}

.videos_page .horizantel_grid figure {
    flex: 0 0 47%;
    max-width: 47%;
    position: relative;
}

.videos_page .horizantel_grid .list_info {
    flex: 0 0 53%;
    max-width: 53%;
    padding: 5px 0 0 36px;
}

.videos_page .horizantel_grid .list_info h3 a {
    font-size: 18px;
    font-weight: 500;
}
.videos_page h2 {
    margin: 0;
    font-weight: bold;
    font-size: 22px;
}
 

.videos_page .horizantel_grid .list_info p {
    font-size: 14px;
} .video_icon {
    /* position: absolute; */
    top: 50%;
    right: 0;
    /* transform: translateY(-50%); */
    transform: translateY(-280%) translateX(260%);
    left: 0;
    margin: auto;
    margin-bottom: -30px;
    cursor: pointer;
}

.videos_page .horizantel_grid .list_info .date {
    padding: 0;
}

.media_link h3 {
    margin-bottom: 0;
}
.media_page {
    padding-bottom: 20px;
}

.media_article a {
    position: relative;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    display: block;
}

.media_article a {
    background: #2b3f73;
}

.media_article a:hover img {
    opacity: 0.5;
}

/* .media_article a:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    background: #2b3f73b8;
    top: 0;
    bottom: 0;
    transition: all .3s;
    -webkit-transition: all .3s;
    transform: scale(0);
} */
.media_article a:hover:after{transform:scale(1);}

.event_detail_info_part {
    padding: 30px 0 0 0;
}

.event_detail_header {
    padding: 0px 24px 18px 24px;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: self-end;
}

.event_left p {
    padding: 0 24px;
    color: #979899;
    font-size: 16px;
    text-align: justify;
}

.event_detail_info_part {
    display: flex;
    flex-wrap: wrap;
}

.event_left {
    flex: 0 0 100%;
    max-width: calc(100% - 318px);
    padding: 0 44px 0 0;
}

.news_left {
    max-width: 100%;
}

.event_detail_right {
    flex: 0 0 318px;
    max-width: 318px;
    border-left: 1px solid #E8E8E8;
    padding: 10px 0 0 24px;
}

.event_detail_right ul {
    list-style: none;
}

.event_detail_right h5 {
    font-size: 14px;
}

.event_detail_right li a {
    color: #979899;
    text-decoration: none;
    font-size: 14px;
    display: inline-block;
    padding: 2px 0;
}

.event_detail_right .social {
    padding-top: 20px;
    display: flex;
    align-items: center;
}

.event_detail_right .social h6{margin:0 8px 0 0px;}

.event_detail_right .social a img{width:20px; max-height:24px;}

.event_detail_right li a:hover {
    color: #2B3F73;
}

.event_detail_header .readmore{font-size:16px;}

.event_detail_right .social a {
    padding: 0 6px;
} 
.sidenav_toggle {
    position: fixed;
    width: 50px;
    background: #fff;
    border: none;
    box-shadow: 0 3px 6px rgba(0,0,0,.2);
    padding: 10px;
    border-radius: 5px;
    left: 0;
    transform: translateX(-60px);
    transition: all .3s;
    -webkit-transition: all .3s;
}

.sidenav_toggle {
    width: 40px;height:40px;
}


/* JATIN */

.work_detail .slick-slider .slick-slide img {width: 100%; } 
.work_detail .slick-slider .slick-slide.slick-active.slick-center.slick-current > div {
        transform: scale(1); 
}
.work_detail .slick-slider .slick-slide.slick-active.slick-center.slick-current > div:after {display: none; }

.work_detail .slick-slider .slick-slide >div 
 {
    transform: scale(-0.9);  
    position: relative;
}
.work_detail .slick-slider .slick-slide >div:after {position: absolute; content:""; background:rgba(0,0,0,0.43); width:100%; height:100%; top: 0; right:0; left:0; bottom:0; }
.work_detail .slick-slide > div {
    margin: 0;
}

.arrow_icon .slick-slider .slick-arrow.slick-prev { 
    background: url('../images/arrow_left.svg') no-repeat center;  
    left: 55px;
    z-index: 9;
    width: 23px;
    height: 46px;
}
.arrow_icon .slick-slider .slick-arrow.slick-next { 
    background: url('../images/arrow_right.svg') no-repeat center;  
    right: 55px;
    z-index: 9;
    width: 23px;
    height: 46px;
}    
.slick-prev:before, .slick-next:before {display: none}
.work_detail_wrap {padding: 70px 0 0;}
.btn_white{ background-color: #fff;}
.detail_content {padding-top: 30px;}
.detail_content h2 {color: #303133; font-size:35px; font-weight: 200;}
.detail_content h6{font-size: 14px; color: #979899; font-weight:normal; }
.detail_content h5 {font-size: 16px; color: #777777;font-weight:normal; letter-spacing: 1px; }
.detail_content p {color: #979899; text-align: justify;}
.detail_next_prev {padding:50px 0; border-top:1px solid #E8E8E8; margin-top: 40px;}
.detail_next_prev a {color: #2B3F73; font-size: 14px; font-weight: normal; text-decoration: none; display:flex; }

.gallery_modal .modal-content {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    position:static; 
}
.gallery_modal .modal-dialog {max-width: 1330px; position:static; }
.gallery_modal .modal-header {
    padding: 0;
    border: 0;
    position: absolute;
    top: 15px;
    right: 50px;
}
.gallery_modal 
 .slick-slide img {
    margin: auto;
}
.gallery_modal button.close {background-color:transparent; border: none; box-shadow: none; opacity: 1; background-image:url(../images/close-white.svg); background-repeat: no-repeat; width: 28px; height: 28px; background-size: cover; }
.gallery_modal button.close span {display: none;}
.modal-backdrop.show {
    opacity: 1;
}

.thumbnail .slick-dots li {
    width: 100px;
    height: 68px;
    margin: 0 2px;
    opacity: 0.6;
}
.thumbnail .slick-dots li.slick-active {opacity: 1;}
.thumbnail .slick-dots {position: static; margin-top:15px}
 .gallery_modal .arrow_icon .slick-slider .slick-arrow.slick-prev {left: 0px;}
 .gallery_modal .arrow_icon .slick-slider .slick-arrow.slick-next {right: 0px;}

.arrow_icon.gray_nav .slick-slider .slick-arrow.slick-prev{background: url('../images/angle_left_gray.svg') no-repeat center;  left:-55px; }
 
.arrow_icon.gray_nav .slick-slider .slick-arrow.slick-next{background: url('../images/angle_right_gray.svg') no-repeat center; right:-55px; }
.solo_slider .slick-slide div  img {max-width: 100%; margin: auto;}
.text-gray p {color: #979899; text-align: justify;}
 
.date_locate, .link_cat  {display: flex; align-items: center; }
.link_cat h5, .link_cat a  { font-size: 16px; color: #2B3F73; text-decoration: none;}
.date_locate .locationfix,  .link_cat h5 + h5 {margin-left: 35px;}
.link_cat h5 span {color:#979899; }

.video_list_info{
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    color: #303133;
    letter-spacing: 1.2px;
    line-height: inherit;
    cursor: pointer;
    padding: 0px 10px;
    margin-top: -10px;
}
 
header .navbar-nav.active a:before {
    width: 100%;
}.right-nav {
    padding: 0;
    border: none;
    background: transparent;
    font-size: 24px;
    color: #2b3f73;
    display: none;
}

.right-nav i {
    color: #2b3f73;
}.left_sidebar.openside {
    transform: translate(0);
}
.modal-header > button.close {
    -webkit-appearance: media-slider;
    background-color: inherit;
    font-size: 30px;
    /* margin-top: -25px; */
}

.modal-backdrop{
    background-color: #2b3f73e0;
}

.newsevent .verticletype {
    left: -15px;
}
.horizantel_grid .video_icon{width:auto;}
.event-page .event-wrapper {
    background: #ececec;     
}

.event-page  .event-tabs {
    background: #ececec;
    display: inline-block;
    width: auto;
    text-decoration: none;
    font-size: 15px;
    color: #2b3f73;
    padding: 10px 15px;
    margin-right: 2px;
    border-radius: 3px;
}
.event-tabs-wr {
    display: inline-block;
    background: #fff;
    margin-bottom: 15px;
    padding: 0 10px 10px 0;
}

.events_listing-outer{padding:15px 20px;}
.event-page .event-tabs.active {
    background: #2b3f73;
    color: #fff;
}.event_listing {
    background: #fff;
    padding: 3px;
}

.event_listing h3:not(.video_list_info) {
    line-height: 0.8!important;
    margin-top: 0px !important;
}

.event_listing h3 a {
    line-height: initial;
}

.addeventatc {
    /* margin-top: 15px; */
    z-index: 101 !important;
}

.events_listing-outer .event_listing:not(.onlyevent .event_listing){
    background: #fbfbfb;    
}
.publications_outer .media_link {
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 10px;
    padding-bottom: 8px;
}
.publications_outer .media_link:first-child {
    padding-top: 0;
}
.publications_outer .media_link .event_listing {
    margin-bottom: 20px;
}

.publications_outer .media_link:last-child {
    border: none;
}
.videos_page .horizantel_grid:last-child{border-bottom:none; margin-bottom:0; padding-bottom:0;}

.Dialog-style_dialog__30eYR{
    z-index: 510000 !important;
}

.rsvp_btn {
    float: right;
}

.event_listing .list_info .btn.outline_btn {
    padding: 10px 12px;
    font-size: 14px;
    border-radius: 0;  
}

.home-module-heading{
    font-size: 27px;
    letter-spacing: 1.2px;
    color: #2B3F73;
    /* font-weight: normal; */
}

.padding-left-5 {
    padding-left: 5px;
}